import {cssTheme as rawCssTheme} from '@vio/atlas-tokens'

import {BrandThemeName} from '@daedalus/core/src/brand/types'

import {theme as bookaway} from './bookaway'
import {theme as checkmybus} from './checkmybus'
import {theme as dealbase} from './dealbase'
import {theme as goseek} from './goseek'
import {theme as holidaypirates} from './holidaypirates'
import {theme as kayak} from './kayak'
import {theme as kiwi} from './kiwi'
import {theme as vio} from './vio'

export enum ThemeNames {
  dealbase = 'dealbase',
  goseek = 'goseek',
  kayak = 'kayak',
  kiwi = 'kiwi',
  vio = 'vio',
  bookaway = 'bookaway',
  holidaypirates = 'holidaypirates',
  checkmybus = 'checkmybus'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ThemeMap = Record<ThemeNames, any>

const themeMap: ThemeMap = {
  dealbase: dealbase,
  goseek: goseek,
  kayak: kayak,
  kiwi: kiwi,
  vio: vio,
  bookaway: bookaway,
  holidaypirates: holidaypirates,
  checkmybus: checkmybus
}

export const getTheme = (themeName: BrandThemeName = ThemeNames.vio) =>
  themeMap[themeName] || vio

const {
  custom,
  semantic,
  components,
  opacity,
  layout,
  shadow: shadows
} = rawCssTheme

const {fonts, typography} = vio

export const cssTheme = {
  colors: {
    ...components,
    ...custom,
    ...semantic
  },
  opacity,
  layout,
  shadows,
  fonts,
  typography
}
