// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgWallet = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.5A2.5 2.5 0 0 1 4.5 2h8.75A2.75 2.75 0 0 1 16 4.75v.854c1.154.326 2 1.387 2 2.646v7A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25V4.75c0-.03.002-.06.005-.088A2.54 2.54 0 0 1 2 4.5Zm1.5 2.292v8.458c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-7c0-.69-.56-1.25-1.25-1.25H4.5c-.356 0-.694-.074-1-.208Zm11-1.292v-.75c0-.69-.56-1.25-1.25-1.25H4.5a1 1 0 0 0 0 2h10Zm-.5 5.75a.25.25 0 1 0 0-.5.25.25 0 0 0 0 .5ZM12.75 11a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
    />
  </svg>
)

export default SvgWallet
